import React from 'react';
import styled from 'styled-components';

export const Paragraph = ({
  children,
  big,
  noMargin,
  white,
  center,
  bold,
  className,
}) => (
  <ParagraphStyles
    className={className}
    noMargin={noMargin}
    white={white}
    center={center}
    big={big}
    bold={bold}
  >
    {children}
  </ParagraphStyles>
);

export const ParagraphStyles = styled.p`
  font-weight: ${(props) => (props.bold ? '600' : '300')};
  margin: ${(props) => (props.noMargin ? '0' : '0 0 2rem 0')};
  font-size: ${(props) => (props.big ? '2.6rem' : '1.8rem')};
  color: ${(props) => (props.white ? 'var(--white)' : 'var(--black)')};
  line-height: ${(props) => (props.big ? '3.9rem' : '3rem')};
  text-align: ${(props) => (props.center ? 'center' : 'unset')};
  max-width: 750px;

  @media (min-width: 768px) {
    margin: ${(props) => (props.noMargin ? '0' : '0 0 2.8rem 0')};
  }
`;

export const Bold = styled.strong`
  font-weight: 600;
`;
