import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Paragraph } from '../components/Paragraph';
import { Header } from '../components/Header';
import Nordstrand from '../assets/images/ActivityCardNordstrand.png';

import BackgroundMap, { Overlay } from '../components/BackgroundMap';
import {
  AdvertisementPin,
  AdvertisementPinSVG,
} from '../assets/icons/AdvertisementPin';
import { WorkPin, WorkPinSVG } from '../assets/icons/WorkPin';
import { CalendarPin, CalendarPinSVG } from '../assets/icons/CalendarPin';
import {
  GreenDotGreenCirle,
  GreenDotGreenCirleSVG,
} from '../assets/icons/GreenDotGreenCirle';
import {
  RedDotRedCirleSmall,
  RedDotRedCirleSmallSVG,
} from '../assets/icons/RedDotRedCirleSmall';
import { BlueDot } from '../assets/icons/BlueDot';
import {
  RedDotBigRedCirle,
  RedDotBigRedCirleSVG,
} from '../assets/icons/RedDotBigRedCirle';
import { ActivityCard } from '../components/ActivityCard';
import { containerPage, itemPage } from '../utils/framer-motion-config';

export default function ContactPage() {
  const container = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        ease: 'easeIn',
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 50 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <ContactPageContainer
      variants={containerPage}
      initial="hidden"
      animate="show"
      layout
    >
      <ContactPageContent variants={itemPage}>
        <TextSection>
          <Header big bolder>
            Kontakt oss
          </Header>
          <Paragraph noMargin>
            <ContactLabel>Post</ContactLabel>
            <a href="mailto:post@community.no">post@community.no</a>
          </Paragraph>
          <Paragraph noMargin>
            <ContactLabel>Tlf</ContactLabel>
            <a href="tel:+47 952 90 246">+47 952 90 246</a>
          </Paragraph>
        </TextSection>
        <TextSection>
          <Paragraph noMargin>
            Vårt hovedkontor er på Nordstrand kulturarena Ekebergveien 243
          </Paragraph>
          <Paragraph noMargin>1166 Oslo</Paragraph>
        </TextSection>
      </ContactPageContent>

      <BackgroundMapContact
        color="var(--lightGrey)"
        variants={container}
        image
        overlay
      >
        <GreenDotGreenCirle size={35} variants={item} />
        <RedDotRedCirleSmall size={50} variants={item} />

        <BlueDotSmall size={45} variants={item} />
        <BlueDotBig size={62} variants={item} />
        <RedDotBigRedCirle size={34} variants={item} />
        <WorkPin variants={item} />
        <AdvertisementPin variants={item} />
        <CalendarPin variants={item} />
        <ActivityCardBuilding src={Nordstrand} variants={item} alt="" />
      </BackgroundMapContact>
    </ContactPageContainer>
  );
}

const ContactLabel = styled.span`
  min-width: 40px;
  display: inline-block;
`;

const BlueDotSmall = styled(BlueDot)``;
const BlueDotBig = styled(BlueDot)``;

const ActivityCardBuilding = styled(ActivityCard)``;

const TextSection = styled.div`
  margin-bottom: ${(props) => (props.bigBottomMargin ? '6rem' : '4rem')};
  max-width: 330px;
  z-index: 50;
`;

const ContactPageContainer = styled(motion.div)`
  background-color: var(--lightGrey);
  position: relative;
  transition: 0.2s ease-in-out;
`;

const ContactPageContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  z-index: 110;

  @media (min-width: 1080px) {
    width: 100%;
    max-width: var(--gridWidth);
    margin: 0 auto;
    padding: 8.6rem 0 2rem 0;
  }
`;

const BackgroundMapContact = styled(BackgroundMap)`
  max-height: 400px;

  ${Overlay} {
    max-height: 400px;
  }

  ${AdvertisementPinSVG} {
    height: auto;
    width: 60px;
    z-index: 10;
    grid-column: 12;
    grid-row: 9;
  }

  ${RedDotBigRedCirleSVG} {
    z-index: 10;
    grid-column: 2;
    grid-row: 6;
    display: none;
  }

  ${WorkPinSVG} {
    z-index: 10;
    height: auto;
    width: 46px;
    grid-column: 3;
    grid-row: 6;
  }

  ${CalendarPinSVG} {
    z-index: 10;
    height: auto;
    width: 60px;
    grid-column: 6;
    grid-row: 6;
    display: none;
  }

  ${ActivityCardBuilding} {
    z-index: 10;
    grid-column: 4 / 11;
    grid-row: 1 / 3;
    min-width: 160px;
  }

  ${GreenDotGreenCirleSVG} {
    z-index: 10;
    grid-column: 6;
    grid-row: 12;
    display: none;
  }

  ${RedDotRedCirleSmallSVG} {
    z-index: 10;
    grid-column: 8;
    grid-row: 6;
    display: none;
  }

  ${BlueDotSmall} {
    z-index: 10;
    grid-column: 3;
    grid-row: 10;
  }

  ${BlueDotBig} {
    z-index: 10;
    grid-column: 8;
    grid-row: 8;
    display: none;
  }

  @media (min-width: 1080px) {
    max-height: 600px;

    ${Overlay} {
      max-height: 600px;
    }

    ${AdvertisementPinSVG} {
      height: auto;
      width: 98px;
      z-index: 10;
      grid-column: 10;
      grid-row: 5;
    }

    ${WorkPinSVG} {
      height: auto;
      width: 98px;
      grid-column: 2;
      grid-row: 8;
    }

    ${ActivityCardBuilding} {
      grid-column: 4 / 10;
      grid-row: 2 / 6;
    }

    ${GreenDotGreenCirleSVG} {
      grid-column: 13;
      grid-row: 12;
      display: block;
    }

    ${RedDotRedCirleSmallSVG} {
      grid-column: 12;
      grid-row: 8;
      display: block;
    }

    ${RedDotBigRedCirleSVG} {
      grid-column: 3;
      grid-row: 10;
      display: block;
    }

    ${BlueDotSmall} {
      grid-column: 5;
      grid-row: 12;
    }

    ${BlueDotBig} {
      grid-column: 9;
      grid-row: 11;
      display: block;
    }

    ${CalendarPinSVG} {
      width: 98px;
      grid-column: 13;
      grid-row: 7;
      display: block;
    }
  }
`;
