import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

export const GreenDotGreenCirle = ({ size, className, variants }) => (
  <GreenDotGreenCirleSVG
    width={size}
    height={size}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    variants={variants}
  >
    <circle
      cx="11.5"
      cy="11.5"
      r="8.5"
      fill="#74E2BE"
      stroke="#CBECE1"
      strokeWidth="6"
    />
  </GreenDotGreenCirleSVG>
);

export const GreenDotGreenCirleSVG = styled(motion.svg)`
  z-index: 120;
`;
