import React from 'react';
import styled from 'styled-components';

// ------------ H1 -------------------
export const Header = ({
  children,
  white,
  big,
  center,
  noMargin,
  bolder,
  className,
}) => (
  <HeaderStyles
    white={white}
    big={big}
    center={center}
    noMargin={noMargin}
    bolder={bolder}
    className={className}
  >
    {children}
  </HeaderStyles>
);

export const HeaderStyles = styled.h1`
  font-weight: ${(props) => (props.bolder ? '700' : '600')};
  line-height: ${(props) => (props.big ? '4rem' : '3.1rem')};
  text-align: ${(props) => (props.center ? 'center' : 'unset')};
  font-size: ${(props) => (props.big ? '2.4rem' : '2.2rem')};
  color: ${(props) => (props.white ? 'var(--white)' : 'var(--black)')};
  margin-bottom: ${(props) => (props.noMargin ? '0' : '0.7rem')};

  @media (min-width: 1024px) {
    font-size: ${(props) => (props.big ? '3rem' : '2.2rem')};
  }
`;

// ------------ H2 -------------------
export const HeaderH2 = ({
  children,
  white,
  big,
  center,
  noMargin,
  className,
}) => (
  <HeaderH2Styles
    white={white}
    big={big}
    center={center}
    noMargin={noMargin}
    className={className}
  >
    {children}
  </HeaderH2Styles>
);

export const HeaderH2Styles = styled.h2`
  margin-bottom: ${(props) => (props.noMargin ? '0' : '2rem')};
  font-weight: 700;
  line-height: 3rem;
  z-index: 10;
  text-align: ${(props) => (props.center ? 'center' : 'unset')};
  font-size: ${(props) => (props.big ? '2.4rem' : '1.6rem')};
  color: ${(props) => (props.white ? 'var(--white)' : 'var(--black)')};
`;
